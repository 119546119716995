// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-and-dragons-js": () => import("./../src/pages/jobs-and-dragons.js" /* webpackChunkName: "component---src-pages-jobs-and-dragons-js" */),
  "component---src-pages-quiz-app-js": () => import("./../src/pages/quiz-app.js" /* webpackChunkName: "component---src-pages-quiz-app-js" */),
  "component---src-pages-simply-js": () => import("./../src/pages/simply.js" /* webpackChunkName: "component---src-pages-simply-js" */)
}

